import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'


export default function Sports() {
  return (
    <>
    <Layout>
      <SEO 
        title="Sports"
        description="This is where we cover some sports here and there. Mostly NBA, NFL and MMA. Not really much NHL or MLB with a few exceptions."
      />
      
      <div className="page-heading-box">
        <h1>SPORTS</h1>
      </div>
    </Layout>
    </>
  )
}